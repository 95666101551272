import { memo, useEffect, useRef } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import BackgroundConfig from './BackgroundConfig';
import StyleConfig from './StyleConfig';

const ConfigPanel = ({
  visible,
  config,
  onConfigChange,
  onBackgroundImageChange,
  onClose,
  language
}) => {
  const { t } = useTranslation(language);

  if (!visible) return null;

  const panelRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (visible && panelRef.current && !panelRef.current.contains(event.target)) {
        onClose?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible, onClose]);

  const handleConfigChange = (key, value) => {
    onConfigChange({ ...config, [key]: value });
  };

  return (
    <div 
      ref={panelRef}
      className="fixed right-0 top-0 h-full w-80 bg-white dark:bg-gray-900 shadow-lg p-6 space-y-6 overflow-y-auto z-[100]"
    >
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold text-gray-900 dark:text-white">{t('buttons.settings')}</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          aria-label={t('buttons.close')}
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <BackgroundConfig
        onImageChange={onBackgroundImageChange}
        language={language}
      />

      <StyleConfig
        cornerRadius={config.cornerRadius}
        paperColor={config.paperColor}
        padding={config.padding}
        onChange={handleConfigChange}
        language={language}
      />
    </div>
  );
};

export default memo(ConfigPanel);